<script lang="ts" setup>
import { GBaseIcon } from '@gem/uikit';
import { computed, ref, watch } from 'vue';
import type { GSidebarTabItemButtonProps } from '../types';
import { cn } from '../../helpers/common';
import CountBadge from '../../components/CountBadge.vue';
import { useSidebarStore } from 'gemx/src/modules/editor/modules/sidebar/stores/sidebar';
import { useI18n } from '@gem/i18n';

const { t } = useI18n({ useScope: 'global' });

const emit = defineEmits<(e: 'click', event: Event) => void>();
const props = withDefaults(defineProps<GSidebarTabItemButtonProps>(), {
  icon: 'more-setting-20',
  iconViewBox: '0 0 20 20',
  active: false,
  width: '40px',
  highlight: false,
  newFeature: false,
  customColorActive: 'bg-dark-250',
  customColorHover: 'hover:bg-dark-150',
});

const showName = ref<boolean>(false);
let showNameTimeout: ReturnType<typeof setTimeout>;

const sidebarStore = useSidebarStore();

const widthStyle = computed(() => {
  if (props.width) {
    return props.width === 'full' ? '100%' : props.width;
  }
  return '';
});

const newFeatureCls = computed(() => {
  if (!props.newFeature) return [];

  return [
    'hover:bg-underlay-dark-blue',
    'after:absolute',
    'after:top-[9px]',
    'after:right-8',
    'after:w-8',
    'after:h-8',
    'after:bg-transparent',
    'after:rounded-full',
    "after:content-['']",
    'after:ring-4',
    'after:ring-inset',
    'after:ring-primary-300',
    ...(props.active ? ['bg-underlay-dark-blue'] : []),
  ];
});

const expandedMode = computed(() => {
  return sidebarStore.getExpandedMode;
});

watch(expandedMode, (value) => {
  clearTimeout(showNameTimeout);
  showNameTimeout = setTimeout(() => {
    showName.value = value;
  }, 50);
});

const handleClick = (e: Event) => {
  emit('click', e);
};
</script>

<template>
  <div>
    <div
      :class="
        cn([
          'relative flex aspect-square h-[40px] w-full cursor-pointer select-none items-center gap-4 rounded-xl pr-4',
          'hover:text-text-dark-500',
          highlight ? 'hover:bg-purple-400' : customColorHover,
          active ? 'text-text-dark-500' : 'text-text-dark-300',
          active ? (highlight ? 'bg-purple-400' : customColorActive) : '',
          expandedMode ? ' justify-between' : ' justify-start',
          ...newFeatureCls,
        ])
      "
      @click="handleClick">
      <div class="flex items-center gap-4">
        <div
          :style="{
            width: widthStyle,
          }"
          class="flex items-center justify-center">
          <GBaseIcon :name="icon" :view-box="iconViewBox" width="20px" height="20px" />
        </div>
        <div v-show="expandedMode && showName" class="text-12 text-white">{{ t(name) }}</div>
      </div>
      <CountBadge
        v-if="props.badge && expandedMode"
        class="bg-primary-500 px-4 text-[11px] leading-[16px]"
        :custom-class="badge.customClass"
        :number="props.badge.number" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Thumbnail } from '../types/image';
import { GButtonV2 } from '@gem/uikit';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  isOpenMoreSetting?: boolean;
  isPreview?: boolean;
  section: {
    name: string;
    thumbnail: Thumbnail;
  };
  isSelectLoading?: boolean;
  isPreviewLoading?: boolean;
  thumbnailPreview?: string;
  isOpenModal?: boolean;
  isFakeSkeleton?: boolean;
}>();

// Emit
const emit = defineEmits<{
  (e: 'select'): void;
  (e: 'preview'): void;
}>();

const handleSelect = () => {
  emit('select');
};

const handlePreview = () => {
  emit('preview');
};
</script>
<template>
  <div
    class="rounded-medium z-5 bg-light-100 group relative !my-0 h-auto w-full overflow-hidden transition-all delay-[0.25s]">
    <div class="thumbnail-img bg-subdued flex items-center justify-center p-24" @click="handlePreview">
      <img :src="section.thumbnail.src" :alt="section.name" class="h-[134px] w-[258px]" />
    </div>
    <div
      class="bg-light-200 group bottom-[-52px] left-0 flex h-[52px] w-full items-center gap-8 overflow-hidden border-t py-8 px-12 transition-all duration-200 group-hover:bottom-0">
      <div
        class="flex h-full max-w-full items-center group-hover:hidden"
        :class="{ '!hidden': isOpenMoreSetting && !isOpenModal }">
        <div class="text-dark-400 text-14 inline-block max-w-full truncate align-middle font-medium leading-6">
          {{ section.name }}
        </div>
      </div>
      <div
        class="hidden w-full gap-8 group-hover:flex"
        :class="{
          '!flex': isOpenMoreSetting && !isOpenModal,
        }">
        <div class="grid w-full grid-cols-2 gap-8">
          <GButtonV2 type="primary" size="medium" :loading="isSelectLoading" @click.stop="handleSelect">{{
            t('Add to page')
          }}</GButtonV2>
          <GButtonV2 type="secondary" :light-mode="true" size="medium" @click.stop="handlePreview">{{
            t('Preview')
          }}</GButtonV2>
        </div>

        <slot name="more-setting"></slot>
      </div>
    </div>
  </div>
</template>

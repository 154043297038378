<template>
  <span data-test="editor-header-control-page-status" class="ml-8 cursor-default">
    <g-badge
      v-if="status == 'default'"
      icon="dot-primary"
      :status="t('Default')"
      :last-action="defaultTooltip"
      class-color="text-green-200"
      class-container="bg-underlay-green-100"
      tooltip-content-class="maxw-[300px] whitespace-normal"></g-badge>
    <g-badge
      v-else-if="status == 'draft'"
      icon="dot-primary"
      :status="t('Draft')"
      :last-action="lastAction"
      class-color="text-primary-200"
      :hidden-text="hiddenText"
      :hidden-tooltip="hiddenTooltip"
      class-container="bg-underlay-blue-150"></g-badge>
    <g-badge
      v-else-if="status === 'needRepublish'"
      icon="dot-primary"
      :status="t('Need republishing')"
      :last-action="lastAction"
      :hidden-text="hiddenText"
      :hidden-tooltip="hiddenTooltip"
      class-color="text-yellow-300"
      class-container="bg-underlay-yellow-100"></g-badge>
    <g-badge
      v-else
      icon="dot-primary"
      :status="t('Published')"
      :last-action="lastAction"
      :hidden-text="hiddenText"
      :hidden-tooltip="hiddenTooltip"
      class-color="text-green-200"
      class-container="bg-underlay-green-100"></g-badge>
  </span>
</template>
<script lang="ts" setup>
import { toRefs, computed } from 'vue';
import GBadge from '../../base/GBadge.vue';
import { toDateString } from '../../helpers/common';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  status?: 'needRepublish' | 'published' | 'draft' | 'default';
  lastUpdatedAt?: string;
  hiddenText?: boolean;
  hiddenTooltip?: boolean;
  defaultTooltip?: string;
};
const props = defineProps<Props>();
const { lastUpdatedAt, status } = toRefs(props);
const lastAction = computed(() => {
  if (status?.value === 'published') {
    return t('Published at {time}', { time: toDateString(lastUpdatedAt?.value || new Date().toString()) });
  }
  return t('Last modified {time}', { time: toDateString(lastUpdatedAt?.value || new Date().toString()) });
});
</script>

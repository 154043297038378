<script lang="ts" setup>
import type { LangKey } from '@gem/element-setting-ui';
import { GSelect, OptionSelect } from '../../index';
import { useI18n, useI18nSetup } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const { locale, setLocaleMessage } = useI18n({ useScope: 'global' });
const { loadLocaleMessageComposeAPI } = useI18nSetup();

const emit = defineEmits(['changeLanguage']);

type LanguageOptions = {
  id: LangKey;
  value: LangKey;
  name: string;
};

const options: LanguageOptions[] = [
  { id: 'en', value: 'en', name: 'English' },
  { id: 'cn', value: 'cn', name: '汉语' },
  { id: 'es', value: 'es', name: 'Español' },
];

const handleChangeLanguage = async (lang: LangKey) => {
  locale.value = lang;
  await loadLocaleMessageComposeAPI(lang, setLocaleMessage);
  emit('changeLanguage', lang);
};
</script>

<template>
  <div class="rounded-medium text-dark-400 w-full bg-white p-16">
    <div class="flex items-end justify-between">
      <span class="font-medium">{{ t('Language Setting') }}</span>
    </div>
    <div class="mt-8">
      <GSelect
        :is-light-mode="true"
        :options="options as OptionSelect[]"
        :active-id="locale"
        @select="handleChangeLanguage">
        <template #button-content>
          {{ options.find((item) => item.value === locale).name }}
        </template>
      </GSelect>
    </div>
  </div>
</template>

<template>
  <text
    xml:space="preserve"
    text-anchor="start"
    font-family="'Alegreya'"
    font-size="12"
    stroke-width="0"
    y="70%"
    x="0"
    >{{ t('icon is not exist') }}</text
  >
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });
type PaginationProps = {
  maxVisibleButtons?: number;
  total?: number;
  perPage?: number;
  currentPage?: number;
  hasMorePages?: boolean;
};
const props = withDefaults(defineProps<PaginationProps>(), {
  maxVisibleButtons: 8,
  total: 0,
  perPage: 0,
  currentPage: 0,
  hasMorePages: false,
});
const startPage = computed(() => {
  if (props.currentPage - props.maxVisibleButtons / 2 <= 1) {
    return 1;
  }

  if (props.currentPage >= props.total / props.perPage - props.maxVisibleButtons / 2) {
    return props.total / props.perPage - props.maxVisibleButtons + 1;
  }

  return props.currentPage - props.maxVisibleButtons / 2;
  // return props.currentPage;
});

const endPage = computed(() => {
  return Math.min(startPage.value + props.maxVisibleButtons - 1, props.total / props.perPage);
});

const pages = computed(() => {
  const range = [];

  for (let i = startPage.value; i <= endPage.value; i += 1) {
    range.push({
      name: i,
      isDisabled: i === props.currentPage,
    });
  }

  return range;
});

const isInFirstPage = computed(() => props.currentPage === 1);
const isInLastPage = computed(() => props.currentPage === props.total / props.perPage);

const emit = defineEmits<{
  (e: 'pageChanged', page: number): void;
}>();

// const onClickFirstPage = () => {
//   emit('pageChanged', 1);
// };
const onClickPreviousPage = () => {
  emit('pageChanged', props.currentPage - 1);
};
const onClickPage = (page: number) => {
  emit('pageChanged', page);
};
const onClickNextPage = () => {
  emit('pageChanged', props.currentPage + 1);
};
const isPageActive = (page: number) => {
  return props.currentPage === page;
};
</script>
<template>
  <div class="flex justify-end">
    <ul class="flex list-none items-center justify-center rounded p-4">
      <li class="inline-block">
        <button
          type="button"
          :disabled="isInFirstPage"
          :aria-label="t('Go to previous page')"
          class="text-small mx-4 flex items-center border-gray-100 p-4 text-gray-600 no-underline hover:bg-gray-100"
          :class="{ 'cursor-not-allowed': isInFirstPage }"
          @click="onClickPreviousPage">
          <g-base-icon name="chevron-left-small" width="16" height="16" view-box="0 0 16 16"></g-base-icon>
        </button>
      </li>

      <li v-for="page in pages" :key="page.name" class="inline-block">
        <span
          v-if="isPageActive(page.name)"
          class="text-primary-300 mx-4 flex cursor-not-allowed items-center p-4 no-underline"
          >{{ page.name }}</span
        >
        <a
          v-else
          class="mx-4 border-gray-100 p-4 text-gray-600 no-underline hover:bg-gray-100"
          href="#"
          role="button"
          @click.prevent="onClickPage(page.name)"
          >{{ page.name }}</a
        >
        <!-- <button
					type="button"
					@click="onClickPage(page.name)"
					:disabled="page.isDisabled"
					:class="{ active: isPageActive(page.name) }"
				>{{ page.name }}</button> -->
      </li>

      <li class="inline-block">
        <button
          type="button"
          :disabled="isInLastPage"
          :aria-label="t('Go to next page')"
          class="text-small mx-4 flex items-center border-gray-100 p-4 text-gray-600 no-underline hover:bg-gray-100"
          :class="{ 'cursor-not-allowed': isInLastPage }"
          @click="onClickNextPage">
          <g-base-icon name="chevron-right-small" width="16" height="16" view-box="0 0 16 16"></g-base-icon>
        </button>
      </li>
    </ul>
  </div>
</template>

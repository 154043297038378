<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import GTooltip from '../../base/GTooltip.vue';
import PageStatus from './PageStatus.vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  pageType?: {
    name?: string;
    icon?: string;
    key?: string;
  };
  pageName?: string;
  isEditThemeSection?: boolean;
  publishStatus?: boolean;
  saved?: boolean;
  lastAction?: string;
  updatedAt?: string;
  publishedAt?: string | null;
  pageStatus?: string;
  notChangeName?: boolean;
  hiddenStatusText?: boolean;
  hiddenTooltip?: boolean;
  backToTooltipText?: string;
  isDefault?: boolean;
  isOrigin?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  pageName: 'Untitled',
  isEditThemeSection: false,
  updatedAt: new Date().toString(),
});
const updatedTime = ref(props.updatedAt);

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'open-rename'): void;
}>();

const onBack = () => {
  emit('back');
};
const statusPage = computed(() => {
  if (props.pageStatus) {
    switch (props.pageStatus) {
      case 'DRAFT':
        return 'draft';
      case 'PUBLISHED':
      case 'PUBLISHING':
      case 'PUBLISH_ERROR':
        return props.publishedAt ? 'published' : 'draft';
      case 'NEED_PUBLISHING':
        return 'needRepublish';
      default:
        return 'draft';
    }
  }
  if (!props.publishStatus && !props.isOrigin) {
    return 'draft';
  } else if (props.lastAction === 'pageSave') return 'needRepublish';
  return 'published';
});

const defaultTooltip = computed(() => {
  if (props.isDefault) {
    switch (props.pageType?.name) {
      case 'Home Page':
        return 'This is the only Home page of your store';
      case 'Product Page':
        return 'All products not assigned to any template will be displayed by default with this template';
      case 'Collection Page':
        return 'All collections not assigned to any template will be displayed by default with this template';
    }
  }
  return '';
});
watch(statusPage, () => {
  updatedTime.value = new Date().toString();
});
</script>

<template>
  <div class="rounded-medium hidden h-40 flex-1 items-center justify-center px-16 after:flex-row sm:flex">
    <template v-if="pageType">
      <g-tooltip placement="bottom" :margin-top="8" class="hidden py-4 sm:flex">
        <div
          data-test="editor-header-control-link-page-type"
          class="rounded-medium hover:text-text-dark-300 text-text-dark-100 flex h-32 cursor-pointer select-none items-center whitespace-nowrap transition-all duration-200"
          @click="onBack">
          <span>
            <g-base-icon :name="(pageType.icon as any)" width="20" height="20"></g-base-icon>
          </span>
          <span class="text-14 font-regular ls:block hidden px-4 text-center">{{ pageType.name }}</span>
          <div class="flex items-center pl-4">
            <span class="font-regular inline-flex text-[14px]"> / </span>
          </div>
        </div>
        <template #content
          ><span class="text-12 flex items-center">{{
            backToTooltipText || `Back to ${pageType?.name?.toLowerCase()} list`
          }}</span>
        </template>
      </g-tooltip>
    </template>
    <div class="static ml-8 hidden items-center sm:flex">
      <g-tooltip v-if="!notChangeName" placement="bottom">
        <span
          class="text-14 lss:max-w-full text-text-dark-300 hover:text-light-200 line-clamp-1 max-w-[70px] cursor-pointer overflow-hidden text-ellipsis break-all text-start font-medium"
          @click="emit('open-rename')">
          {{ pageName }}
        </span>
        <template #content>
          <span class="text-12 flex items-center">
            {{ t('Rename') }}
            {{ isEditThemeSection ? 'theme section' : 'page title' }}</span
          >
        </template>
      </g-tooltip>
      <div v-else>
        <g-tooltip content-class="w-[236px] mt-4" placement="bottom" :disabled="!isEditThemeSection || !pageType">
          <span
            v-if="pageType"
            class="text-14 text-text-dark-300 line-clamp-1 overflow-hidden text-ellipsis break-all text-start font-medium">
            {{ pageType.key === 'GP_FUNNEL_PAGE' ? 'Sales page' : pageName }}
          </span>
          <template #content>
            <div class="w-[220px] whitespace-pre-wrap">
              {{ t('Renaming Theme Sections is unavailable on the Shopify Starter plan') }}
            </div>
          </template>
        </g-tooltip>
      </div>
      <PageStatus
        v-if="!isEditThemeSection && isDefault"
        class="lss:block hidden"
        status="default"
        :default-tooltip="defaultTooltip"></PageStatus>

      <PageStatus
        v-if="!isEditThemeSection"
        class="lss:block hidden"
        :hidden-text="hiddenStatusText"
        :status="statusPage"
        :last-updated-at="updatedTime"
        :hidden-tooltip="hiddenTooltip"></PageStatus>
    </div>
    <div>
      <slot name="more"></slot>
      <slot name="rename"></slot>
    </div>
  </div>
</template>

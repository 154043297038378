<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import GMenuItem from '../../elements/MenuItem.vue';
import { GButtonV2 } from '@gem/uikit';
import { computed, ref, watch } from 'vue';
import type { DomainItem } from '../../types/domain';
import DnsInfo from './DNSInfo.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  domain: DomainItem;
  primaryDomain?: string;
  defaultDomain?: string;
  defaultDns: string;
  ipAddress: string;
  makingPrimaryDomain?: DomainItem;
  isMisconfig?: boolean;
  isRemoving?: boolean;
  isCheckingSatisfied?: boolean;
  isMakingPrimary?: boolean;
  isEnabledViewLive?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'remove-domain'): void;
  (e: 'set-primary-domain'): void;
  (e: 'reverify-domain'): void;
  (e: 'open-domain'): void;
}>();
const enableTrashMode = ref<boolean>(false);
const EXIST_PROJECT_DOMAIN_ERROR = 'existing_project_domain';

const primaryDomainIcon = computed(() => (isDomainSatisfied.value ? 'normal-domain-fill' : 'error'));

const isDomainSatisfied = computed<boolean>(() => !props.isMisconfig && !!props.domain?.isVerified);

const isMakingOtherDomainPrimary = computed<boolean>(
  () => !!props.makingPrimaryDomain && props.domain.id !== props.makingPrimaryDomain.id,
);

const isLoadingButtons = computed<boolean>(
  () => !!props.isCheckingSatisfied || !!props.isRemoving || !!props.isMakingPrimary,
);

const isErrorExistProjectDomain = computed<boolean>(() => {
  if (!props.domain.verification?.length) {
    return false;
  }
  const error = props.domain.verification[0];
  return error?.reason === EXIST_PROJECT_DOMAIN_ERROR;
});

const setAsPrimary = () => {
  emit('set-primary-domain');
};

const reverifyDomain = () => {
  emit('reverify-domain');
};

const removeDomain = () => {
  emit('remove-domain');
};

const openDomain = () => {
  if (props.isCheckingSatisfied) {
    return;
  }
  if (props.domain.isPrimary || isDomainSatisfied.value) {
    emit('open-domain');
  }
};

watch(
  () => props.isRemoving,
  () => {
    if (!props.isRemoving) {
      enableTrashMode.value = false;
    }
  },
);
</script>

<template>
  <g-menu-item :menu-item-style="'large'" :enable-content="true"
    ><template #icon
      ><div :key="primaryDomainIcon">
        <span
          :class="{
            'text-red-300': !isDomainSatisfied,
            '!text-primary-300': isDomainSatisfied && domain.isPrimary,
            '!text-text-light-300': isCheckingSatisfied,
          }">
          <g-base-icon :name="primaryDomainIcon" width="16" height="16" view-box="0 0 16 16"></g-base-icon
        ></span></div
    ></template>
    <template #text>
      <div class="flex items-end">
        <div class="max-w-[350px] truncate">
          <span
            :class="{
              'text-primary-300 cursor-pointer': isDomainSatisfied && !isCheckingSatisfied,
              'text-red-300': domain.isPrimary && !isDomainSatisfied && !isCheckingSatisfied,
              'text-text-light-300 cursor-default': isCheckingSatisfied || (!domain.isPrimary && !isDomainSatisfied),
            }"
            @click="openDomain"
            >{{ domain?.domain }}</span
          >
        </div>
        <span
          v-if="domain?.isPrimary"
          class="text-12 rounded-medium ml-10 px-4 py-[1px] text-white"
          :class="{
            'bg-primary-300': isDomainSatisfied && !isCheckingSatisfied,
            'bg-red-300': !isDomainSatisfied && !isCheckingSatisfied,
            'bg-text-light-300': isCheckingSatisfied,
          }"
          >{{ t('Primary') }}</span
        >
      </div>
    </template>
    <template #description>
      <span class="dark" :class="{ 'text-red-300': !isDomainSatisfied, 'text-text-light-300': isCheckingSatisfied }">
        <template v-if="!domain.isVerified">
          <span v-if="isErrorExistProjectDomain"> {{ t('This domain is already used for another store') }} </span>
          <span v-else> {{ t('The DNS Record is missing, let create new one using below values') }} </span>
        </template>
        <template v-else-if="isMisconfig">
          {{ t('The DNS Record is not matched, please recheck and update it') }}
        </template>
        <template v-else>
          {{
            domain.isPrimary ? t('All instant pages will be directed to this domain') : `Redirect to ${primaryDomain}`
          }}
        </template>
      </span>
    </template>
    <template #action>
      <div v-if="domain?.isPrimary && isDomainSatisfied" class="flex flex-row items-center">
        <GButtonV2
          v-if="!isCheckingSatisfied"
          type="ghost"
          size="medium"
          only-icon="open-new-tab"
          icon-size="16"
          icon-view-box="0 0 16 16"
          :disable="!isEnabledViewLive"
          @click="openDomain">
        </GButtonV2>
        <GButtonV2 v-else type="tertiary" :disable="true" :loading="true" size="medium">
          <span class="text-12 font-medium">{{ t('Verifying') }}</span>
        </GButtonV2>
      </div>
      <div v-else-if="!enableTrashMode" class="flex flex-row items-center gap-8">
        <GButtonV2 v-if="isCheckingSatisfied" type="tertiary" :disable="true" :loading="true" size="medium">
          <span class="text-12 font-medium">{{ t('Verifying') }}</span>
        </GButtonV2>
        <GButtonV2
          v-else-if="isDomainSatisfied"
          type="tertiary"
          :disable="isLoadingButtons || isMakingOtherDomainPrimary"
          :loading="isLoadingButtons"
          size="medium"
          @click="setAsPrimary">
          <span class="text-12 font-medium">{{ t('Make Primary') }}</span>
        </GButtonV2>
        <GButtonV2 v-else type="tertiary" size="medium" @click="reverifyDomain">
          <span class="text-12 font-medium">{{ t('Reverify') }}</span>
        </GButtonV2>
        <GButtonV2
          v-if="!domain.isPrimary"
          :disable="isLoadingButtons || domain.domain === defaultDomain"
          type="tertiary"
          size="medium"
          @click="!isLoadingButtons ? (enableTrashMode = true) : ''">
          <span class="gap-8">
            <g-base-icon name="trash" width="16" height="16" view-box="0 0 16 16"></g-base-icon>
          </span>
        </GButtonV2>
      </div>
      <div v-if="!!enableTrashMode && domain.domain !== defaultDomain" class="flex flex-row items-center gap-8">
        <GButtonV2
          v-if="!isLoadingButtons"
          type="tertiary"
          size="medium"
          :disable="isLoadingButtons"
          @click="enableTrashMode = false">
          <span class="text-12"> {{ t('Cancel') }} </span>
        </GButtonV2>
        <GButtonV2
          type="danger"
          size="medium"
          :loading="isLoadingButtons"
          :disable="isLoadingButtons"
          @click="removeDomain">
          <span class="text-12"> {{ t('Delete') }} </span>
        </GButtonV2>
      </div>
    </template>
    <template #content>
      <div v-if="!domain.isVerified && !isErrorExistProjectDomain">
        <dns-info
          :domain="domain.domain || ''"
          :error-verification="domain.verification"
          :default-dns="defaultDns"
          :ip-address="ipAddress"></dns-info>
      </div>
      <div v-else-if="isMisconfig && !isErrorExistProjectDomain">
        <dns-info :domain="domain.domain || ''" :default-dns="defaultDns" :ip-address="ipAddress"></dns-info>
      </div>
    </template>
  </g-menu-item>
</template>

<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import WarningAlert from './WarningAlert.vue';
import { ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  title?: string;
  url?: string;
  handle?: string;
  image?: string;
  description?: string;
  maximumSize?: number;
  allowedFiles?: string[];
  isLoadingUpload?: boolean;
  isHomePage?: boolean;
  shopId: string;
  pageType?: string;
  shopDomain?: string;
  shopifyPlan?: string;
  isInstant?: boolean;
};

const emit = defineEmits<{
  (e: 'uploadImage', formData: FormData): void;
  (e: 'deleteImage'): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  image: '',
  maximumSize: 2 * 1024 * 1024, // Byte
  allowedFiles: () => ['.jpg', '.jpeg', '.gif', '.png', '.webp'],
});

const parseHandle = computed(() => {
  if (!props.handle) return { handleSplit: [], patchName: '' };
  const handleSplit = props.handle.split('/');
  const patchName = props.handle.split('/')[0];

  return { handleSplit, patchName };
});

const pageUrl = computed(() => {
  if (props.isHomePage) {
    return props.url;
  }

  if (props.pageType === 'GP_STATIC') {
    return `${props.shopDomain}/pages/${props.handle}`;
  }

  if (props.pageType === 'GP_ARTICLE') {
    if (parseHandle.value.handleSplit.length > 1 && props.handle) {
      const handleName = props.handle.replace(`${parseHandle.value.patchName}/`, '');
      return `${props.shopDomain}/blogs/${parseHandle.value.patchName}/${handleName}/`;
    }

    return `${props.shopDomain}/blogs/news/${props.handle}/`;
  }

  return props.url + '/' + props.handle;
});

const warning = ref('');
const file = ref<HTMLInputElement>();

const allowedFilesRegex = (allowedFiles: string[]) => {
  return new RegExp(allowedFiles.join('|') + '$');
};

const handleChangeFile = (event: any) => {
  if (event.target?.files?.length) {
    const [file] = event.target.files;
    const regex = allowedFilesRegex(props.allowedFiles);

    if (regex.test(file.name.toLowerCase())) {
      if (file.size < props.maximumSize) {
        postUploadImage(file);
      } else {
        warning.value = 'size';
      }
    } else {
      warning.value = 'format';
    }
    event.target.value = '';
  }
};

const shopifyPlan = (props.shopDomain ?? '').toLocaleLowerCase() === 'trial' ? 'TRIAL' : 'PAID';
const postUploadImage = (urlImage: string) => {
  const formData = new FormData();
  formData.append('file', urlImage);
  formData.append('shopID', props.shopId);
  formData.append('shopifyPlan', shopifyPlan);
  formData.append('fileType', 'GALLERY');
  formData.append('storage', 'SHOPIFY');
  warning.value = '';
  emit('uploadImage', formData);
};

const handleDelete = () => {
  emit('deleteImage');
};
const isShowWarningAlert = computed(() => {
  return props.pageType === 'GP_COLLECTION' || props.pageType === 'GP_PRODUCT' || props.pageType === 'GP_INDEX';
});
</script>

<template>
  <div class="text-dark-400 w-full rounded-xl bg-white p-16">
    <div class="mb-16 flex items-end justify-between">
      <span class="text-14 text-dark-400 font-medium leading-[24px]">{{ t('Social Sharing Image Preview') }}</span>
      <span class="text-12 text-text-light-300">{{ t('(Recommended size: 1200 x 630 px)') }}</span>
    </div>

    <div
      v-if="!isShowWarningAlert"
      class="bg-light-400 overflow-hidden rounded-[6px] border border-solid border-gray-200">
      <label for="file-upload">
        <div
          class="bg-light-500 after:bg-dark-400 group relative flex h-[330px] w-full cursor-pointer items-center justify-center transition-all duration-200 after:absolute after:inset-0 after:opacity-0 after:content-[''] hover:after:opacity-70"
          :class="{ 'after:hidden': !image }">
          <img
            v-if="image && !isLoadingUpload"
            :src="image"
            :alt="t('image social')"
            class="absolute h-full w-full object-cover" />
          <div class="z-5 gap-8 font-medium group-hover:inline-flex" :class="image ? 'hidden' : 'inline-flex'">
            <div class="relative flex items-center rounded-xl bg-white py-8 px-16">
              <span class="text-text-light-300 mr-8 inline-flex">
                <g-base-icon name="general-upload" viewBox="0 0 14 14" width="14" height="14"></g-base-icon>
              </span>
              <span class="text-14 select-none font-medium">{{ t('Upload Image') }}</span>
            </div>
            <GButtonV2 v-if="image" type="danger" @click="handleDelete">{{ t('Delete') }}</GButtonV2>
          </div>

          <span v-if="!image" class="text-12 text-text-light-300 absolute top-[60%] w-full text-center">
            {{ t('Supported file: .jpg, .jpeg, .png, .gif, .webp') }}
          </span>
          <div
            v-if="isLoadingUpload"
            class="absolute z-50 flex h-full w-full items-center justify-center bg-neutral-900">
            <div
              class="custom-control-loading-icon h-header w-header animate-spin rounded-full border-4 border-solid border-t-transparent"></div>
          </div>
          <input id="file-upload" ref="file" type="file" accept="image/*" class="hidden" @change="handleChangeFile" />
        </div>
      </label>
      <div class="p-12">
        <div v-if="warning" class="mb-8 flex gap-8">
          <span class="text-yellow-400">
            <g-base-icon name="Warning" viewBox="0 0 18 16" width="18" height="16"></g-base-icon>
          </span>
          <span v-if="warning === 'size'" class="text-14 text-yellow-400">
            {{
              t(
                'Your image exceeds maximum file size (2MB). Please crop or compress the image to reduce file size before upload again.',
              )
            }}
          </span>
          <span v-else-if="warning === 'format'" class="text-14 text-yellow-400">
            {{ t('Image format not supported. Please upload images in JPG, JPEG, GIF, PNG, WEBP formats.') }}
          </span>
        </div>
        <div class="text-12 text-dark-400 max-w-[616px] overflow-hidden text-ellipsis font-medium">{{ title }}</div>
        <div class="text-12 text-primary-300 max-w-[616px] overflow-hidden text-ellipsis font-medium">
          <a :href="`https://${pageUrl}`" target="_blank" class="no-underline">{{ pageUrl }}</a>
        </div>
        <div class="text-12 text-dark-400 max-w-[616px] overflow-hidden text-ellipsis">{{ description }}</div>
      </div>
    </div>
    <WarningAlert v-else type="SOCIAL" :shop-domain="shopDomain" :page-type="pageType" />
  </div>
</template>

<template>
  <div class="rounded-medium flex w-full bg-yellow-200 p-16">
    <svg
      class="h-20 min-w-[20px]"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0728 2.86863C10.5976 2.04379 9.40241 2.04379 8.9272 2.86863L1.41575 15.9064C0.942891 16.7271 1.5381 17.75 2.48855 17.75H17.5114C18.4619 17.75 19.0571 16.7271 18.5842 15.9064L11.0728 2.86863ZM10 5.43064C10.3452 5.43064 10.625 5.7092 10.625 6.05282V11.6524C10.625 11.996 10.3452 12.2746 10 12.2746C9.65482 12.2746 9.375 11.996 9.375 11.6524V6.05282C9.375 5.7092 9.65482 5.43064 10 5.43064ZM10 13.7678C9.44771 13.7678 9 14.2135 9 14.7633C9 15.3131 9.44771 15.7588 10 15.7588C10.5523 15.7588 11 15.3131 11 14.7633C11 14.2135 10.5523 13.7678 10 13.7678Z"
        fill="#494949" />
    </svg>
    <span
      v-if="pageType === 'GP_INDEX' && type === 'SEO'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{ t('Please go to') }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{
        t('Online Store > Preferences')
      }}</a>
      {{ t('to edit SEO info for Home Page') }}</span
    >
    <span
      v-if="pageType === 'GP_INDEX' && type === 'SOCIAL'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{ t('Please go to') }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{
        t('Online Store > Preferences')
      }}</a>
      {{ t('to edit Social Sharing Image for Home Page') }}</span
    >

    <span
      v-if="pageType === 'GP_PRODUCT' && type === 'SEO'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{ t('Please go to') }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{ t('Product in Shopify') }}</a>
      {{ t('to edit SEO info for each product') }}</span
    >
    <span
      v-if="pageType === 'GP_PRODUCT' && type === 'SOCIAL'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{
        t(
          'For Product Page, when you post a link from your online store to social media, the featured image for that page is shown. You can edit it in',
        )
      }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{ t('Product') }}</a></span
    >

    <span
      v-if="pageType === 'GP_COLLECTION' && type === 'SEO'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{ t('Please go to') }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{ t('Product > Collection') }}</a>
      {{ t('to edit SEO info for each collection') }}</span
    >
    <span
      v-if="pageType === 'GP_COLLECTION' && type === 'SOCIAL'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{
        t(
          'For Collection Page, when you post a link from your online store to social media, the featured image for that page is shown. You can edit it in',
        )
      }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{ t('Product > Collection') }}</a>
    </span>

    <span
      v-if="pageType === 'GP_ARTICLE' && type === 'SEO'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{ t('Please go to') }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{ t('Blog Posts') }}</a>
      {{ t('to edit SEO info for each article') }}</span
    >
    <span
      v-if="pageType === 'GP_ARTICLE' && type === 'SOCIAL'"
      class="text-14 text-dark-400 font-regular ml-8 leading-[21px]"
      >{{
        t(
          'For Blog Posts, when you post a link from your online store to social media, the featured image for that page is shown. You can edit it in',
        )
      }}
      <a class="text-primary-300 no-underline" :href="pageData.uri" target="_blank">{{ t('Blog Posts') }}</a>
    </span>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type Props = {
  pageType?: string;
  shopDomain?: string;
  type?: string;
};
const props = defineProps<Props>();

const PAGE_DATA: {
  [key: string]: { title: string; uri: string };
} = {
  GP_COLLECTION: {
    title: 'Collection',
    uri: `https://${props.shopDomain}/admin/collections`,
  },
  GP_PRODUCT: {
    title: 'Product',
    uri: `https://${props.shopDomain}/admin/products`,
  },
  GP_INDEX: {
    title: 'Home',
    uri: `https://${props.shopDomain}/admin/online_store/preferences`,
  },
  GP_ARTICLE: {
    title: 'Article',
    uri: `https://${props.shopDomain}/admin/articles`,
  },
};

const pageData = computed(() => {
  if (props.pageType) {
    return PAGE_DATA[props.pageType] || PAGE_DATA.GP_INDEX;
  }
  return PAGE_DATA.GP_INDEX;
});
</script>
